<template>
    <b-row
        v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
        class="content-header"
    >
        <!-- Content Left -->
        <b-col class="content-header-left mb-2" cols="12" md="9">
            <b-row class="breadcrumbs-top">
                <b-col cols="12">
                    <h2
                        v-if="$route.meta.hasOwnProperty('i18nTitle')"
                        class="content-header-title float-left pr-1 mb-0"
                    >
                        <span v-if="$route.meta.i18nTitle.hasOwnProperty('tc')">
                            {{
                                $tc(
                                    $route.meta.i18nTitle.tc,
                                    $route.meta.i18nTitle.index
                                )
                            }}
                        </span>
                        <span
                            v-else-if="
                                $route.meta.i18nTitle.hasOwnProperty('t')
                            "
                        >
                            {{ $tc($route.meta.i18nTitle.t) }}
                        </span>
                    </h2>
                    <h2
                        v-else
                        class="content-header-title float-left pr-1 mb-0"
                    >
                        {{ $route.meta.pageTitle }}
                    </h2>
                    <div class="breadcrumb-wrapper">
                        <b-breadcrumb>
                            <b-breadcrumb-item to="/">
                                <feather-icon
                                    icon="HomeIcon"
                                    size="16"
                                    class="align-text-top"
                                />
                            </b-breadcrumb-item>
                            <b-breadcrumb-item
                                v-for="item in $route.meta.breadcrumb"
                                :key="item.text"
                                :active="item.active"
                                :to="item.to"
                            >
                                <span v-if="item.hasOwnProperty('i18nText')">
                                    <span
                                        v-if="
                                            item.i18nText.hasOwnProperty('tc')
                                        "
                                    >
                                        {{
                                            $tc(
                                                item.i18nText.tc,
                                                item.i18nText.index
                                            )
                                        }}
                                    </span>
                                    <span
                                        v-else-if="
                                            item.i18nText.hasOwnProperty('t')
                                        "
                                    >
                                        {{ $tc(item.i18nText.t) }}
                                    </span>
                                </span>
                                <span v-else>
                                    {{ item.text }}
                                </span>
                            </b-breadcrumb-item>
                        </b-breadcrumb>
                    </div>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BRow, BCol } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"

export default {
    directives: {
        Ripple,
    },
    components: {
        BBreadcrumb,
        BBreadcrumbItem,
        BRow,
        BCol,
    },
}
</script>
