import { ref, watch } from "@vue/composition-api"
import Api from "@/services/api"
import _ from "lodash"

export default function useAutoSuggest(props) {
    const filteredData = ref({})

    const searchQuery = ref("")
    const resetsearchQuery = () => {
        searchQuery.value = ""
    }

    /**
     * Filter group against provided query
     * Grp Structure:
     * {
     *    key: 'title',
     *    data: [
     *      title: 'Admin', img: 'someImage.png',
     *      title: 'Template', img: 'otherImage.png',
     *    ]
     * }
     * @param {Object} grp Group object to perform filter on
     * @param {String} query Query string to filter
     */
    const filterGrp = (grp, query) => {
        const exactEle = grp.data.filter(item =>
            item[grp.key].toLowerCase().startsWith(query.toLowerCase())
        )
        const containEle = grp.data.filter(
            // prettier-ignore
            item => !item[grp.key].toLowerCase().startsWith(query.toLowerCase()) && item[grp.key].toLowerCase().indexOf(query.toLowerCase()) > -1
        )
        return exactEle.concat(containEle).slice(0, props.searchLimit)
    }

    const handleSearchQueryUpdate = _.debounce(async val => {
        if (val === "") {
            filteredData.value = {}
        } else {
            let resultData = {}
            const search = searchQuery.value

            const companiesRequest = Api.findAll("companies", {
                filter: {
                    search,
                },
                fields: {
                    companies: "name,businessTaxId",
                },
            })
            const peopleRequest = Api.findAll("people", {
                filter: {
                    search,
                },
                fields: {
                    people: "name,documentNumber",
                },
            })

            const response = await Promise.all([
                peopleRequest,
                companiesRequest,
            ])

            const peopleResult = response[0].data
            const companiesResult = response[1].data

            resultData = {
                people: {
                    key: "name",
                    data: peopleResult,
                },
                companies: {
                    key: "name",
                    data: companiesResult,
                },
                pages: {
                    title: "name",
                    data: [],
                },
            }
            const queriedData = {}
            const dataGrps = Object.keys(resultData)

            dataGrps.forEach((grp, i) => {
                queriedData[dataGrps[i]] = filterGrp(resultData[grp], val)
            })

            filteredData.value = queriedData
        }
    }, 1000)

    watch(searchQuery, val => handleSearchQueryUpdate(val))

    return {
        searchQuery,
        resetsearchQuery,
        filteredData,
    }
}
