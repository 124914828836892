export default [
    {
        header: "menu.donations.label",
        icon: "GiftIcon",
        children: [
            {
                title: "menu.donations.material",
                icon: "PackageIcon",
                route: "list-material-donations",
                action: "list",
                resource: "material-donations",
            },
        ],
    },
]
