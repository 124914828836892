/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import donations from "./donations"
import contacts from "./contacts"
import actions from "./actions"
import systemManagement from "./system-management"

// Toggle the line comments below to see an complete example with apps and pages
// import appsAndPages from "./apps-and-pages"
const appsAndPages = []
// Array of sections
export default [
    ...appsAndPages,
    ...contacts,
    ...actions,
    ...donations,
    ...systemManagement,
]
