<template>
    <b-nav-item-dropdown
        right
        toggleClass="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
    >
        <template #button-content>
            <div class="d-sm-flex d-none user-nav">
                <p class="user-name font-weight-bolder mb-0">
                    {{ userData.fullName || userData.username }}
                </p>
                <span class="user-status">{{ userData.role }}</span>
            </div>
            <b-avatar
                size="40"
                :src="userData.avatar"
                variant="light-primary"
                badge
                class="badge-minimal"
                badgeVariant="success"
            >
                <feather-icon
                    v-if="!userData.fullName"
                    icon="UserIcon"
                    size="22"
                />
            </b-avatar>
        </template>

        <b-dropdown-item
            :to="{ name: 'profile' }"
            linkClass="d-flex align-items-center"
        >
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item linkClass="d-flex align-items-center" @click="logout">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>Logout</span>
        </b-dropdown-item></b-nav-item-dropdown
    >
</template>

<script>
import {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
} from "bootstrap-vue"
import { initialAbility } from "@/libs/acl/config"
import { avatarText } from "@core/utils/filter"

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
    },
    data() {
        return {
            userData: JSON.parse(localStorage.getItem("userData")),
            avatarText,
        }
    },
    methods: {
        async logout() {
            window.localStorage.clear()
            await this.$http.post("/logout")
            // Reset ability
            this.$ability.update(initialAbility)
            // Redirect to login page
            this.$router.push({ name: "auth-login" })
        },
    },
}
</script>
