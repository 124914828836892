import Vue from "vue"

export default [
    {
        title: "menu.contacts.label",
        icon: "UsersIcon",
        children: [
            {
                title: "menu.contacts.add",
                icon: "UserPlusIcon",
                route: null,
                onClick: () => {
                    Vue.prototype.$createPeopleModal.show()
                },
                action: "create",
                resource: "people",
            },
            {
                title: "menu.contacts.people",
                icon: "UserIcon",
                route: "list-people",
                action: "list",
                resource: "people",
            },
            {
                title: "menu.contacts.companies.label",
                icon: "BriefcaseIcon",
                route: "list-companies",
                action: "list",
                resource: "companies",
            },
        ],
    },
]
