<template>
    <p class="clearfix mb-0 clear">
        <span class="float-md-left d-block d-md-inline-block mt-25">
            COPYRIGHT © {{ new Date().getFullYear() }}
            <b-link class="ml-25" href="https://notuz.com.br" target="_blank"
                >Notuz</b-link
            >
            <span class="d-none d-sm-inline-block"
                >, Todos os diretos reservados</span
            >
        </span>
    </p>
</template>

<script>
import { BLink } from "bootstrap-vue"

export default {
    components: {
        BLink,
    },
}
</script>

<style scoped>
p {
    clear: both;
}
</style>
